import ReactModal from 'react-modal'
import { useModal } from 'react-modal-hook'

import { Icon } from '../shared/components/Icon'
import ChangeIcon from './ChangeSheet.svg'
import { ChangeSheetPopup } from './ChangeSheetPopup'

export function ChangeSheetButton({ setSheetId, ...props }) {
  const customStyles = {
    overlay: {
      zIndex: 100,
      background: 'rgba(0, 0, 0, 0.6)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 101
    }
  }

  const [showModal, hideModal] = useModal(() => {
    function changeSheet(sheetId) {
      setSheetId(sheetId)
      hideModal()
    }
    return (
      <ReactModal isOpen onRequestClose={hideModal} style={customStyles}>
        <ChangeSheetPopup hideModal={hideModal} changeSheet={changeSheet} />
      </ReactModal>
    )
  })

  return (
    <Icon onClick={showModal}>
      <img src={ChangeIcon.src} alt='年表の変更' title='年表の変更' width={24} height={24} decoding='async' />
    </Icon>
  )
}
