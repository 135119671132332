import React, { useState, useEffect } from 'react'

export function FigureStill({ url, alt, style }) {
  const [isInvalidImage, setisInvalidImage] = useState(true)

  useEffect(() => {
    if (!url) return
    async function imageValidation(url) {
      const isValid = await isValidImage(url)
      setisInvalidImage(!isValid)
    }
    imageValidation(url)
  }, [])

  if (!url) return null
  if (isInvalidImage) return null
  return <img src={url} alt={alt} decoding='async' loading='lazy' style={style} />
}

async function isValidImage(url) {
  const image = new Image()
  image.src = url
  return new Promise((resolve, reject) => {
    image.addEventListener('error', () => reject('image is invalid: load error'))
    image.addEventListener('abort', () => reject('image is invalid: load aborted'))
    image.addEventListener('load', () => resolve(true), false)
  })
}
