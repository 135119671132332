import { loadDefaultJapaneseParser } from 'budoux'
import sanitizeHtml from 'sanitize-html'

const parser = loadDefaultJapaneseParser()

export function createBudouxedHTML(string) {
  return {
    __html: parser.translateHTMLString(sanitizeHtml(string))
  }
}
