import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

export function Seasons({ seasons, year }) {
  if (!seasons || seasons.length === 0) return null
  return (
    <ul>
      {seasons.map((season, i) => (
        <Season key={i} season={season} year={year} />
      ))}
    </ul>
  )
}

function Season({ season, year }) {
  return (
    <StyledLi>
      <SeasonTitle style={{ color: season.category.color }}>
        {season.title}
        <span>｜</span>
        <span>{season.start_year === year ? '開始' : '終了'} </span>
      </SeasonTitle>
    </StyledLi>
  )
}

const StyledLi = styled.li`
  font-size: var(--tableview--column-font-size);
  /* TODO: #263 TableViewの、Season, Event要素のheightが、行数 * 2emになるようにしたい */
  line-height: 1.6em; /* 2emだと、改行があった場合に、行間が間伸びしすぎる */

  /* 左右のcolumnと上のツラ合わせにしたいので、
  padding-topは0.2emで固定。
  padding-bottomで調整したい。
  padding-bottom: calc(height - 行数 * 1.6em - 0.2em) */
  padding: 0.2em 0;
`

const SeasonTitle = styled.span``
