import React, { useState, useEffect, useContext, useRef, forwardRef } from 'react'
import styled, { css } from 'styled-components'

import COLOR from '../constants/Color'

import { Event } from './Event'

import { DurationContext } from '../../context/DurationContext'
import { ThemeContext } from '../../context/ThemeContext'

import { createBudouxedHTML } from '../../utils/createBudouxedHTML'
import { hexToRgba } from '../../utils/colorConversion'

export const Season = forwardRef(({ season, className, index, marginCount, ...props }, ref) => {
  const duration = useContext(DurationContext)
  const theme = useContext(ThemeContext)
  const yearHeight = theme.yearHeight

  const borderColor = hexToRgba(season.category.color, 0.3)

  const seasonHeight = season.is_endless
    ? (new Date().getFullYear() - season.start_year + 1) * yearHeight
    : (season.end_year - season.start_year + 1) * yearHeight

  const seasonStyle = {
    height: `${seasonHeight}px`,
    top: `${(season.start_year - duration.startDecade) * yearHeight}px`,
    left: `calc(${index + marginCount} * var(--timelineview--season-whole-width) + 32px)`,
    borderLeftColor: borderColor
  }

  const afterBarTop = `${seasonHeight - 0.5}px`

  const titleElement = useRef(null)
  const [titleHeight, setTitleHeight] = useState(0)
  useEffect(() => {
    setTitleHeight(titleElement.current.clientHeight)
  }, [season, titleElement.current?.clientHeight])

  // year: eventCount
  const eventCountTable = { 0: 0 }
  const events = season.events.map((event) => {
    if (eventCountTable[`${event.year}`] !== undefined) {
      eventCountTable[`${event.year}`] += 1
    } else {
      eventCountTable[`${event.year}`] = 0
    }

    return (
      <Event
        event={event}
        key={event.title + event.year}
        index={eventCountTable[event.year]}
        seasonTitle={season.title}
        seasonStartYear={season.start_year}
      />
    )
  })

  const stemExtensionStyle = getStemExtensionStyle(
    eventCountTable,
    season.is_endless ? new Date().getFullYear() : season.end_year,
    seasonHeight,
    yearHeight
  )

  return (
    <StyledLi style={seasonStyle} className={`${className} Season`}>
      <ScrollMarker style={{ marginTop: `-${titleHeight + 48}px` }} ref={ref} />
      {season.is_endless && <AfterBarEndless top={afterBarTop} color={borderColor} />}
      {stemExtensionStyle && <StemExtension style={stemExtensionStyle} data-isextension={true} color={borderColor} />}
      <Title
        style={{ marginTop: `-${titleHeight}px` }}
        ref={titleElement}
        dangerouslySetInnerHTML={createBudouxedHTML(season.title)}
        year={`${season.start_year}-${season.end_year ? season.end_year : 'now'}`}
      />
      <ul>{events}</ul>
    </StyledLi>
  )
})

Season.displayName = 'Season'

function getStemExtensionStyle(eventCountTable, seasonEndYear, seasonHeight, yearHeight) {
  const eventYears = Object.keys(eventCountTable)
  const eventLastYear = eventYears[eventYears.length - 1]
  if (eventLastYear - seasonEndYear <= 0) return null

  return {
    top: `${seasonHeight + 7}px`,
    height: `${(eventLastYear - seasonEndYear) * yearHeight - 10}px`
  }
}

const StyledLi = styled.li`
  position: absolute;
  color: ${COLOR.base.text};
  border-left-style: solid;
  border-left-width: 20px;
  border-left-color: ${COLOR.base.border};
`

const Bar = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: -11px;
  width: 20px;
  border-top-style: solid;
  border-top-width: var(--timelineview--season-border-left-width);
  border-top-color: ${COLOR.base.border};
`

const BeforeBar = Bar

const AfterBar = styled(Bar)`
  margin-left: -9px;
  top: ${({ top }) => top};
  border-left-style: dotted;
  border-left-width: var(--timelineview--season-border-left-width);
  border-left-color: ${COLOR.season.after_stem_border};
`

const AfterBarEndless = styled(AfterBar)`
  width: 0;
  height: 0;
  border: none;
  left: -1px;
  /* 矢印の矢尻 */
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 60px 30px 0 30px;
    border-color: ${({ color }) => `${color} transparent transparent transparent;`};
  }
`

const StemExtension = styled.div`
  position: absolute;
  left: -20px;
  display: block;
  width: 20px;
  background-image: ${({ color }) => `url(${createBorderImageInBase64(color)})`};
  background-repeat: repeat-y;
`

const ScrollMarker = styled.div`
  position: absolute;
  top: 0;
  height: 0;
  display: block;
  visibility: hidden;
  margin-left: -4rem; /*
    左にマージンを確保して、Seasonのタイトルが見えるようにする。 
    smoothscroll-polyfillで、
      block: 'center', 
      inline: 'center',
    への対応がまだなく、現状、中央ではなく、左上に表示される。
    その際に、タイトルの文字が隠れないようにしている
  */
`

const YearMixin = ({ year }) => css`
  &::before {
    content: '${({ year }) => `${year}`}';
    position: absolute;
    font-size: var(--base-font-size);
    font-weight: 400;
    line-height: var(--base-line-height);
    width: 5.5rem;
    left: -5.75rem;
    top: 0;
    text-align: right;
  }
`

const Title = styled.div`
  position: absolute;
  top: 0;
  margin-left: calc(-1.25rem - 3px);
  font-size: var(--listview--season-font-size);
  font-weight: bold;
  width: calc(var(--timelineview--season-width) + 1rem);
  line-height: var(--timelineview--season-line-height);
  ${({ year }) => YearMixin({ year })};
`

function createBorderImageInBase64(color) {
  const width = 20
  const height = 9

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')
  context.fillStyle = color
  context.lineWidth = 0
  context.beginPath()
  context.rect(0, 0, width, 3)
  context.fill()

  return canvas.toDataURL('image/png', 1.0)
}