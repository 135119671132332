import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import COLOR from '../constants/Color'
import { TableFigure } from './TableFigure'
import { hexToRgba } from '../../utils/colorConversion'
import { Lattice } from '../shared/mixins/Lattice'

export function EventPopup({ event }) {
  return (
    <StyledFlexBox
      hasImage={!!event.popup.imageUrl}
      hasDescription={!!event.popup?.description}
      style={{ borderColor: event.category.color }}
    >
      {event.popup.imageUrl && (
        <ImageContainer color={hexToRgba(event.category.color, 0.6)} bgColor='transparent'>
          <TableFigure url={event.popup.imageUrl} description={event.popoup?.description} />
        </ImageContainer>
      )}
      {event.popup?.description && (
        <Description hasImage={!!event.popup.imageUrl}>{event.popup.description}</Description>
      )}
    </StyledFlexBox>
  )
}

const StyledFlexBox = styled.div`
  display: flex;
  margin: ${({ hasImage }) => (hasImage ? '0 0 1em' : '0 0 1em 4em')};
  width: ${({ hasImage, hasDescription }) =>
    hasImage && hasDescription ? 'calc(604px - 2em)' : hasImage ? '251px' : 'calc(608px - 4px - 6em)'};
  padding: ${({ hasImage }) => (hasImage ? '1em' : '1em 1em 0.75em')};
  border: 2px solid;
  border-radius: 8px;
  box-sizing: content-box;
`

const ImageContainer = styled.div`
  position: relative;
  width: 251px;
  height: 251px;
  ${({ color, bgColor }) => Lattice}
`

const Description = styled.div`
  vertical-align: top;
  margin-top: -0.25em;
  margin-left: ${({ hasImage }) => (hasImage ? '0.75em' : 0)};

  color: ${COLOR.base.text};
  font-weight: normal;
  hyphens: auto;
  white-space: pre-wrap;
  /*
    改行文字があった場合は、改行する。空白文字はそのまま。
    https://developer.mozilla.org/ja/docs/Web/CSS/white-space
  */
`
