import React, { useEffect, useContext, useRef } from 'react'
import styled, { css } from 'styled-components'

import { Season } from './Season'
import { Event } from './Event'
import { DurationContext } from '../../context/DurationContext'

import { hexToRgba } from '../../utils/colorConversion'

export function Category({ category, isFirst, isLast, ...props }) {
  const duration = useContext(DurationContext)

  const categoryColorInHex = category.color

  // marginCountSum: Seasonごとに、同じ年にEventが、最大いくつ重複するかを足し合わせた数
  let marginCountSum = 0

  const seasonRefs = useRef([])

  useEffect(() => {
    seasonRefs.current = seasonRefs.current.slice(0, category.seasons.length)
  }, [category.seasons])

  const seasons = []
  const seasonIndexes = []

  category.seasons.forEach((season, index) => {
    const marginCount = marginCountSum
    const overlapping = getMaxNumberOfOverlappingEventsInYear(season)
    marginCountSum += overlapping
    seasons.push(
      <Season
        className={index == 0 ? 'Season--First' : null}
        season={season}
        key={'season-' + season.title}
        index={index}
        marginCount={marginCount}
        ref={(el) => (seasonRefs.current[index] = el)}
      />
    )

    // 親Nodeが
    // postion: sticky;
    // なので、絶対値で位置を指定すると、スクロールに伴ってズレる。
    // Eventが単年に複数重なるSeasonの場合のみ、Eventの重なった個数に応じてmargin-rightで位置調整
    const indexStyle = {
      marginRight: `calc(${overlapping} * var(--timelineview--season-whole-width))`
    }

    seasonIndexes.push(
      <Index
        onClick={() => {
          handleClickSeasonIndex(index)
        }}
        style={indexStyle}
        key={'seasonIndex-' + season.title}
      >
        <Wrapper color={hexToRgba(categoryColorInHex, 0.6)}>
          <SeasonDuration>
            {season.start_year}–{season.end_year}
          </SeasonDuration>
          <SeasonTitle>{season.title}</SeasonTitle>
        </Wrapper>
      </Index>
    )
  })

  const scrollToSeason = (seasonElement) => {
    if (seasonElement) {
      seasonElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    }
  }

  const handleClickSeasonIndex = React.useCallback((index) => {
    scrollToSeason(seasonRefs.current[index])
  }, [])

  // Seasonに属さないEventは
  // 年表の一番上を始点に座標を計算する
  // key: 年
  // value: その年にあるEventの回数-1
  const eventCountTable = { 0: 0 }

  const events = category.events.map((event) => {
    if (eventCountTable[`${event.year}`] !== undefined) {
      eventCountTable[`${event.year}`] += 1
    } else {
      eventCountTable[`${event.year}`] = 0
    }

    return (
      <Event
        event={event}
        key={'event-' + event.title + event.year}
        seasonStartYear={duration.startDecade}
        seasonTitle={category.title}
        seasonIndex={seasons.length + marginCountSum}
        index={eventCountTable[event.year]}
      />
    )
  })

  // eventCountTableMax: 同じ年の、Category直下のEventは、最大いくつか
  const eventCountTableMax = Math.max(...Object.values(eventCountTable))

  // isFirst : paddingLeft分
  // isLast : paddingRight分
  // events.length > 0 : Category直下のEvent　があった場合
  const wholeWidthCount =
    (isFirst ? 0.25 : 0) +
    (isLast ? 1 : 0) +
    seasons.length +
    (events.length > 0 ? 1 : 0) +
    eventCountTableMax +
    marginCountSum

  const paddingLeft = isFirst ? 'calc(var(--timelineview--season-whole-width) / 4)' : 0
  const paddingRight = isLast ? 'var(--timelineview--season-whole-width)' : 0

  const categoryStyle = {
    width: `calc(${wholeWidthCount} * var(--timelineview--season-whole-width)`,
    backgroundColor: hexToRgba(categoryColorInHex, 0.1),
    paddingLeft,
    paddingRight
  }

  return (
    <StyledLi style={categoryStyle}>
      <SeasonList>{seasons}</SeasonList>
      <EventList>{events}</EventList>
      <Title color={hexToRgba(categoryColorInHex, 0.3)}>{category.title}</Title>
      <div /> {/* for cleafix */}
      <SeasonIndex color={categoryColorInHex}>{seasonIndexes}</SeasonIndex>
    </StyledLi>
  )
}

function getMaxNumberOfOverlappingEventsInYear(season) {
  const seasonYearsTable = { 0: 0 }

  season.events.forEach((event) => {
    if (seasonYearsTable[`${event.year}`] !== undefined) {
      seasonYearsTable[`${event.year}`] += 1
    } else {
      seasonYearsTable[`${event.year}`] = 0
    }
  })
  return Math.max(...Object.values(seasonYearsTable))
}

const StyledLi = styled.li`
  position: relative;
  display: block;
  min-height: 100%;
`

const Title = styled.div`
  display: inline-block;
  position: sticky;
  top: 40px;
  left: 0;
  font-size: 80px;
  height: 0;
  color: ${({ color }) => color};
  font-feature-settings: 'palt';
  font-kerning: auto;

  /* Safari 9+のみ適用 */
  @supports (paint-order: fill) and (-webkit-marquee-speed: 0) {
    display: block;
  }
  /* iOS Safari のみ適用 */
  @supports (-webkit-touch-callout: none) {
    display: block;
  }
`

const SeasonList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`
const EventList = styled.ul`
  position: relative;
  display: block;
`

const SeasonIndex = styled.ol`
  display: inline-block;
  position: sticky;
  top: 0;
  font-size: var(--listview--season-font-size);
  line-height: var(--timelineview--season-line-height);
  padding: 1em 0;
  font-weight: bold;
  color: ${({ color }) => color};
  z-index: 10;

  /* Safari 9+のみ適用 */
  @supports (paint-order: fill) and (-webkit-marquee-speed: 0) {
    display: block;
  }
  /* iOS Safari のみ適用 */
  @supports (-webkit-touch-callout: none) {
    display: block;
  }
`

const Index = styled.li`
  display: inline-block;
  width: var(--timelineview--season-whole-width);
  vertical-align: top;
  cursor: pointer;
`

const Wrapper = styled.div`
  margin-top: 1.5em;
  display: block;
`

const InnerMixin = css`
  display: block;
  width: max-content;
  max-width: calc(100% - 32px);
  margin-left: 2em;
  background: white;
  box-sizing: border-box;
`
const SeasonDuration = styled.div`
  ${InnerMixin};
  font-feature-settings: 'tnum'; // 数字等幅
`

const SeasonTitle = styled.div`
  ${InnerMixin};
  font-feature-settings: 'halt';
  &:hover {
    border-bottom: 2px solid ${({ color }) => color};
  }
`
