import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { FigureYoutube } from '../Figure/FigureYouTube'
import { FigureVimeo } from '../Figure/FigureVimeo'
import { FigureStill } from '../Figure/FigureStill'

import { isYoutubeUrl, isVimeoUrl, isImageUrl } from '../../utils/validations/validateUrl'

import { Lattice } from '../shared/mixins/Lattice'

export function EventDetail({ event, close }) {
  if (!event) return null

  return (
    <Wrapper>
      <Content>
        <CloseButton onClick={close}>×</CloseButton>
        {/* #ADD8E6 is --lattice-color */}
        <ImageContainer hasImage={!!event.popup.imageUrl} color='#ADD8E6' bgColor='transparent'>
          <EventFigure url={event.popup.imageUrl} description={event.popup.description} />
        </ImageContainer>

        <EventTitle>{event.title}</EventTitle>
        <Meta>
          {event.season && (
            <SeasonTitle style={{ color: event.category.color }}>
              {event.season?.title}
              <span style={{ fontFeatureSettings: 'normal' }}>｜</span>
            </SeasonTitle>
          )}
          <CategoryTitle>{event.category.title}</CategoryTitle>
          <span style={{ fontFeatureSettings: 'normal' }}>｜</span>
          <span>{event.year}</span>
        </Meta>
        <Description>{event.popup.description}</Description>
      </Content>
    </Wrapper>
  )
}

function EventFigure({ url, description }) {
  if (!url) return null
  const stillStyle = { width: 660, height: 320, objectFit: 'contain' }

  const videoStyle = {
    // object-fit: contain; と同じ状態にする
    width: 427,
    height: 320, // 4:3
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }

  if (isYoutubeUrl(url)) return <FigureYoutube url={url} style={videoStyle} />
  if (isVimeoUrl(url)) return <FigureVimeo url={url} style={videoStyle} />
  if (isImageUrl(url)) return <FigureStill url={url} alt={description} style={stillStyle} />

  return null
}

const Wrapper = styled.div`
  position: fixed;
  width: var(--listview--event-detail-width);
  height: 100vh;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 2px solid var(--lattice-color);
  background-color: var(--listview--background-color);
`

const Content = styled.article`
  position: relative;
  margin: 140px 32px auto;
  width: var(--listview--event-detail--content-width);
  height: auto;
  color: var(--listview--text-color);
`

const ImageContainer = styled.div`
  display: block;
  position: relative;
  width: calc(var(--listview--event-detail--content-width) + 1px); // 格子をきれいに表示するために、1px追加
  height: ${({ hasImage }) => (hasImage ? '321px' : '41px')}; // 格子をきれいに表示するために、1px追加
  ${({ color, bgColor }) => Lattice};
`

const EventTitle = styled.h1`
  margin: 35px 0 8px;
  font-size: var(--listview--event-detail--title-font-size);
  line-height: 1.75;
`

const Meta = styled.div`
  font-size: var(--listview--event-detail--meta-font-size);
  line-height: 1.75;
  font-weight: bold;
`

const SeasonTitle = styled.span``

const CategoryTitle = styled.span`
  color: var(--listview--text-color);
`

const Description = styled.p`
  margin-top: 32px;
  font-size: var(--listview--event-detail--description-font-size);
  line-height: 1.75;
  font-weight: bold;
  /* Google Sheetsの改行を、反映させる */
  hyphens: auto;
  white-space: pre-wrap;
`

const CloseButton = styled.button`
  position: absolute;
  top: -48px;
  right: 0px;
  font-size: 32px;
  line-height: 1;
  color: var(--listview--text-color);
  height: 1em;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`
