import styled from 'styled-components'
import Size from '../constants/Size'

export function RadioGroup({ radios, value, setValue, ...props }) {
  const inputs = radios.items.map((item) => (
    <InputWrapper key={`${radios.title}_${item.value}`}>
      <input
        type='radio'
        name={radios.title}
        id={`${radios.title}_${item.value}`}
        style={{ cursor: 'pointer' }}
        value={item.value}
        checked={value === item.value}
        onChange={() => setValue(item.value)}
      />{' '}
      <label htmlFor={`${radios.title}_${item.value}`} style={{ cursor: 'pointer' }}>
        {item.label}
      </label>
    </InputWrapper>
  ))

  return <StyledRadioGroup>{inputs}</StyledRadioGroup>
}

const StyledRadioGroup = styled.div`
  font-size: ${Size.popup.item.font_size}px;
`

const InputWrapper = styled.div`
  display: inline-block;
  margin-right: 1em;
  cursor: pointer;
`
