import { buildUrlForGetSheetTitle } from './buildUrl'

export async function getSheetTitle(sheetId = null) {
  const url = buildUrlForGetSheetTitle(sheetId)

  try {
    const response = await fetch(url)
    const json = await response.json()
    return json.properties.title
  } catch (error) {
    alert('Failed: getSheetTitle')
    console.error(error)
  }
}
