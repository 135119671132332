import styled from 'styled-components'

import COLOR from '../constants/Color'

export function SelectValue({ name, options, value, setValue, ...props }) {
  function onChange(e) {
    if (options.type === 'number') {
      setValue(parseInt(e.target.value, 10))
    } else {
      setValue(e.target.value)
    }
  }

  return (
    <StyledSelect defaultValue={value} onChange={onChange}>
      {options.items.map((item) => (
        <option value={item.value} key={`${name}-${item.label}`}>
          {item.label}
        </option>
      ))}
    </StyledSelect>
  )
}

const StyledSelect = styled.select`
  margin: 0;
  margin-top: 3px;
  padding: 0 1.25em;
  color: ${COLOR.popup.content.text};
  border: none;
  border-bottom: 1px solid ${COLOR.popup.content.border};
`
