import { buildUrlForGetCategories } from './buildUrl'
import { DEFAULT_SHEET_ID } from '../constants'

export async function getCategories(sheetId) {
  const url = buildUrlForGetCategories(sheetId)

  try {
    const response = await fetch(url)
    const json = await response.json()

    // FIXME: #53: getCategoris(sheetID) is too heavy
    const categories = buildCategories(json)
    const categories_with_seasons = attachSeasons(json, categories)
    const categories_with_seasons_and_events = attachEvents(json, categories_with_seasons)
    const relations = buildRelations(json)
    return {
      categories: categories_with_seasons_and_events,
      relations: relations
    }
  } catch (error) {
    alert(`Failed: Get Contents:\n\n${error.message}`)
    console.error(error)
  }
}

function buildCategories(json) {
  // category: json.valueRanges[0].values
  return json.valueRanges[0].values.map((cat) => {
    return {
      title: cat[0],
      color: cat[1],
      start_year: cat[2],
      end_year: cat[3],
      seasons: [],
      events: []
    }
  })
}

function attachSeasons(json, categories) {
  // season: json.valueRanges[1].values
  if (json.valueRanges[1].values === undefined) return categories

  json.valueRanges[1].values.forEach((season) => {
    const category = categories.find((cat) => cat.title === season[1])
    category.seasons.push({
      title: season[0],
      start_year: season[2],
      end_year: season[3] || null,
      is_endless: !season[3],
      events: [],
      category
    })
  })
  return categories
}

function attachEvents(json, categories) {
  // events: json.valueRanges[2].values
  if (json.valueRanges[2].values === undefined) return categories

  json.valueRanges[2].values.forEach((event) => {
    const category = categories.find((cat) => cat.title === event[3])
    if (!category)
      throw new Error(
        `Google Sheetsの'Event'シートを確認してください。\n以下のtitleのEventに、'season' か 'category'を設定してください。\n
        title: "${event[0]}"
      `
      )
    const season = category.seasons.find((season) => season.title === event[2])
    const eventObject = {
      id: event[7],
      title: event[0],
      year: event[1],
      url: event[4],
      // POP UP
      popup: {
        description: event[5],
        imageUrl: event[6]
      },
      season,
      category
    }

    if (season) {
      season.events.push(eventObject)
    } else {
      category.events.push(eventObject)
    }
  })

  return categories
}

function buildRelations(json) {
  if (json.valueRanges[3] === undefined) return []
  if (json.valueRanges[3].values === undefined) return []
  return json.valueRanges[3].values.map((relation) => ({
    from: {
      title: relation[0],
      id: relation[2],
      year: relation[4]
    },
    to: {
      title: relation[1],
      id: relation[3],
      year: relation[5]
    }
  }))
}