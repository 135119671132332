// from: https://jsfiddle.net/eliranmal/fUNK7/
export function isValidURL(url) {
  let urlPattern =
    "(https?|ftp)://(www\\.)?(((([a-zA-Z0-9.-]+\\.){1,}[a-zA-Z]{2,4}|localhost))|((\\d{1,3}\\.){3}(\\d{1,3})))(:(\\d+))?(/([a-zA-Z0-9-._~!$&'()*+,;=:@/]|%[0-9A-F]{2})*)?(\\?([a-zA-Z0-9-._~!$&'()*+,;=:/?@]|%[0-9A-F]{2})*)?(#([a-zA-Z0-9._-]|%[0-9A-F]{2})*)?"
  urlPattern = '^' + urlPattern + '$'
  const regex = new RegExp(urlPattern)

  return regex.test(url)
}

export function isYoutubeUrl(url) {
  const regex =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
  return regex.test(url)
}

export function isVimeoUrl(url) {
  const regex =
    /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/
  return regex.test(url)
}

export function isImageUrl(url) {
  return url.match(/\.(jpeg|jpg|gif|png|svg)$/) != null
}
