import styled from 'styled-components'

export function ToggleSwitch({ value, setValue, ...props }) {
  function onChange(e) {
    setValue(e.target.checked)
  }

  const size = {
    width: 34,
    height: 20
  }

  return (
    <Wrapper size={size}>
      <StyledInput id='toggle' type='checkbox' size={size} defaultChecked={value} onChange={onChange} />
      <StyledLabel htmlFor='toggle' size={size} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: ${({ size }) => `${size.width}px`};
  height: ${({ size }) => `${size.height}px`};
`

const StyledLabel = styled.label`
  display: inline-block;
  position: relative;
  width: ${({ size }) => `${size.width}px`};
  height: ${({ size }) => `${size.height}px`};
  background: #ccc;
  border-radius: ${({ size }) => `${size.height}px`};
  box-sizing: border-box;
  transition: 0.4s;
  &:after {
    content: '';
    position: absolute;
    width: ${({ size }) => `${size.height}px`};
    height: ${({ size }) => `${size.height}px`};
    border-radius: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
  }
`

const StyledInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
  &:checked + ${StyledLabel} {
    background-color: #007aff;
    &:after {
      left: ${({ size }) => `${size.width - size.height + 7}px`};
    }
  }
`
