import React, { useState, useContext, forwardRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import dynamic from 'next/dynamic'

import Size from './constants/Size'

import { CategoryTable } from './TimelineView/CategoryTable'
import { Relation } from './TimelineView/Relation'

import { ThemeContext } from '../context/ThemeContext'

const GlobalStyle = createGlobalStyle`
  :root {
    --listview--season-font-size: ${Size.season.font_size}px;
    --timelineview--season-line-height: ${Size.season.line_height}px;
    --timelineview--season-width: ${({ seasonLineLength }) => `${Size.base.font_size * seasonLineLength}px`};
    --timelineview--season-whole-width: ${({ seasonLineLength }) =>
      `${Size.base.font_size * (seasonLineLength + 4) + Size.season.padding_left}px`};
    --timelineview--season-padding-left: ${Size.season.padding_left}px;
    --timelineview--season-border-left-width: ${Size.season.border_left_width}px;
    --timelineview--event-margin-left: ${({ seasonLineLength }) => `${Size.base.font_size * (seasonLineLength + 4)}px`};
    --timelineview--event-title-lines: ${({ yearHeight }) => yearHeight / Size.base.line_height};
    --timelineview--year-font-size: ${Size.base.font_size * 4}px;
    --timelineview--year-width: ${Size.base.font_size * 10}px;
  }
`

const Years = dynamic(() => import('./TimelineView/Years').then((mod) => mod.Years), { ssr: false })

export function TimelineView({ categories, relations, seasonLineLength, resizeListener, ...props }) {
  const { yearHeight, drawLineEvery } = useContext(ThemeContext)

  return (
    <>
      <GlobalStyle yearHeight={yearHeight} seasonLineLength={seasonLineLength} />
      <StyledDiv yearHeight={yearHeight} drawLineEvery={drawLineEvery} className='RenderTarget'>
        <Years />
        <CategoryTable categories={categories} resizeListener={resizeListener} />
        {relations.map((relation) => (
          <Relation from={relation.from} to={relation.to} key={`${relation.from.id}_${relation.to.id}`} />
        ))}
      </StyledDiv>
    </>
  )
}

const handleBackgroundImage = (yearHeight, drawLineEvery) => {
  // console.log(yearHeight)
  const lines = yearHeight / Size.base.line_height
  return `/images/background_${drawLineEvery}_${lines}.png`
}

const StyledDiv = styled.div`
  position: relative;
  margin-top: var(--header-height);
  min-width: 100%;
  background-image: ${({ yearHeight, drawLineEvery }) => `url(${handleBackgroundImage(yearHeight, drawLineEvery)})`};
  background-repeat: repeat;
`