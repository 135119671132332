import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import urlParser from 'js-video-url-parser/lib/base'
import 'js-video-url-parser/lib/provider/youtube'

export function FigureYoutube({ url, style }) {
  const [showVideo, setShowVideo] = useState(false)

  if (!url) return null

  const videoInfo = urlParser.parse(url)
  const embedUrl = urlParser.create({ videoInfo, format: 'embed', params: videoInfo.params })
  const thumbUrl = urlParser.create({ videoInfo, format: 'longImage' })

  function show() {
    setShowVideo(true)
  }

  return (
    <VideoWrapper showVideo={showVideo} style={style}>
      {showVideo ? (
        <VideoIframe type='text/html' src={embedUrl} frameBorder='0' allowFullScreen></VideoIframe>
      ) : (
        // TODO: #68 検討する：最初は非表示なので、iframeそのまま表示ででもいいかも。
        <ThumbnailWrapper>
          <PlayButton onClick={show} aria-label='Play Video' decoding='async' loading='lazy' />
          <Thumbnail onClick={show} src={thumbUrl} alt='Video' decoding='async' loading='lazy' />
        </ThumbnailWrapper>
      )}
    </VideoWrapper>
  )
}

const VideoWrapper = styled.div`
  position: relative;
  aspect-ratio: ${({ showVideo }) => (showVideo ? 16 / 9 : 4 / 3)};
  height: 100%;
  overflow: hidden;
`

const VideoIframe = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const ThumbnailWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: auto;
  height: 100%;
  overflow: hidden;
`

const Thumbnail = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`

const PlayButton = styled.button`
  background: #282828;
  border-radius: 50% / 10%;
  color: #fff;
  font-size: 1em;
  height: 3em;
  padding: 0;
  text-align: center;
  text-indent: 0.1em;
  transition: all 150ms ease-out;
  width: 4em;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: none;
  opacity: 0.8;
  cursor: pointer;
  z-index: 9;
  &:hover {
    background: #ff0000;
  }
  &:before {
    background: inherit;
    border-radius: 5% / 50%;
    bottom: 9%;
    content: '';
    left: -5%;
    position: absolute;
    right: -5%;
    top: 9%;
  }
  &:after {
    border-style: solid;
    border-width: 1em 0 1em 1.732em;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
    content: ' ';
    font-size: 0.75em;
    height: 0;
    margin: -1em 0 0 -0.75em;
    top: 50%;
    position: absolute;
    width: 0;
  }
`
