export function getEventsByYear(events) {
  const eventsByYears = {}

  events.forEach((event) => {
    const year = `${event.year}`
    if (!eventsByYears[year]) {
      eventsByYears[year] = []
    }
    eventsByYears[year].push(event)
  })

  return eventsByYears
}

export function categoriesToEvents(categories) {
  const eventsWithSeason = categories.map((category) => category.seasons.map((season) => season.events).flat()).flat()
  const eventsWithoutSeason = categories.map((category) => category.events).flat()

  return eventsWithSeason.concat(eventsWithoutSeason)
}
