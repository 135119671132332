export function getIntParam(url, regex) {
  const param = url.match(regex)
  if (!param || param.length === 0) return null
  // console.log(regex, parseInt(param[0].split('=')[1], 10))
  return parseInt(param[0].split('=')[1], 10)
}

export function getParam(url, name) {
  const regex = new RegExp(`[?&]${name}=([^&]*)`)
  const param = url.match(regex)
  // console.log(param)
  return param ? param[1] : null
}
