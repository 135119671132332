import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import linkIcon from '../images/event/link-icon.svg'
import popupIcon from '../images/event/popup-icon.svg'

import COLOR from '../constants/Color'
import { EventPopup } from './EventPopup'

import { isValidURL } from '../../utils/validations/validateUrl'

export function Events({ events }) {
  if (!events || events.length === 0) return null

  return (
    <ul>
      {events.map((event, i) => (
        <Event key={i} event={event} />
      ))}
    </ul>
  )
}

function Event({ event }) {
  const hasPopup = event.popup.description || event.popup.imageUrl

  const [isPopupShown, setIsPopupShown] = useState(false)
  function handleIsPopupShown() {
    setIsPopupShown(!isPopupShown)
  }

  return (
    <StyledLi>
      <div>
        {event.season && (
          <SeasonTitle style={{ color: event.category.color }}>
            {event.season?.title}
            <span>｜</span>
          </SeasonTitle>
        )}
        {hasPopup ? (
          <span style={{ display: 'inline-block' }}>
            <EventTitleWithPopup onClick={handleIsPopupShown} style={{ borderColor: event.category.color }}>
              {event.title} <PopupIcon src={popupIcon.src} alt='popup-icon' isPopupShown={isPopupShown} />
            </EventTitleWithPopup>
            {isPopupShown && <BorderForPopup style={{ borderColor: event.category.color }} />}
          </span>
        ) : isValidURL(event.url) ? (
          <EventTitleWithLink
            href={event.url}
            target='_blank'
            rel='noopener noreferrer'
            style={{ borderColor: event.category.color }}
          >
            {event.title} <Icon src={linkIcon.src} alt='link-icon' />
          </EventTitleWithLink>
        ) : (
          <EventTitle>{event.title}</EventTitle>
        )}
      </div>

      {hasPopup && isPopupShown && <EventPopup event={event} />}
    </StyledLi>
  )
}

const StyledLi = styled.li`
  position: relative;
  font-size: var(--tableview--column-font-size);
  /* TODO: #263 TableViewの、Season, Event要素のheightが、行数 * 2emになるようにしたい */
  line-height: 1.6em; /* 2emだと、改行があった場合に、行間が間伸びしすぎる */

  /* 左右のcolumnと上のツラ合わせにしたいので、
  padding-topは0.2emで固定。
  padding-bottomで調整したい。
  padding-bottom: calc(height - 行数 * 1.6em - 0.2em) */
  padding: 0.2em 0;
`

const SeasonTitle = styled.span``

const EventTitle = styled.span`
  color: ${COLOR.base.text};
`

const EventTitleWithPopup = styled.span`
  color: ${COLOR.base.text};
  box-moddel: border-box;
  border-bottom: 2px solid;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`

const EventTitleWithLink = styled.a`
  text-decoration: none;
  color: ${COLOR.base.text};
  box-moddel: border-box;
  border-bottom: 2px solid;
  &:hover {
    opacity: 0.6;
  }
`

const Icon = styled.img`
  vertical-align: top;
  margin-top: 0.25em;
  margin-left: 0;
  width: 1em;
`

const PopupIcon = styled(Icon)`
  transform: ${({ isPopupShown }) => (isPopupShown ? 'rotate(-90deg)' : 'rotate(90deg)')};
`

const BorderForPopup = styled.div`
  display: block;
  margin: 0;
  margin-top: -1px;
  width: 50%;
  height: 1em;
  border-right: 2px solid;
`