import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

// import popupIcon from '../images/event/popup-icon.svg'
// import linkIcon from '../images/event/link-icon.svg'

import Size from './constants/Size'

import { YearCategoryTable } from './TableView/YearCategoryTable'

import { getEventsByYear, categoriesToEvents } from '../utils/getEventsWithCondition'
import { getSeasonByYear } from '../utils/getSeasonWithCondtion'

const GlobalStyle = createGlobalStyle`
  :root {
    --tableview--background-color: #FCFCF9;
    --tableview--column-font-size: ${(Size.base.font_size * 4) / 3}px; // 16px
    --tableview--column-padding: 0 ${(Size.base.font_size * 4) / 3}px; // 16px
    --tableview--column-width: ${((Size.base.font_size * 4) / 3) * 40}px; // 540px
    --tableview--header-font-size: ${Size.base.font_size * 4}px; // 48px
  }
`

export function TableView({ categories }) {
  const eventsByYears = getEventsByYear(categoriesToEvents(categories))
  const seasonsByYear = getSeasonByYear(categories)

  return (
    <>
      <GlobalStyle />
      {/* 
         TODO: #265 TableViewのwidthを、正確に計算する
         572 = var(--tableview--column-width) +  var(--tableview--column-padding) * 2
       */}
      <Wrapper style={{ minWidth: 572 * categories.length + 'px' }}>
        <YearCategoryTable categories={categories} eventsByYears={eventsByYears} seasonsByYear={seasonsByYear} />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  margin-top: var(--header-height);
  background-color: var(--tableview--background-color);
`
