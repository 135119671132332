import styled, { css } from 'styled-components'

export const Button = styled.button`
  min-width: 64px;
  margin: 8px;
  margin-left: 0;
  padding: 10px 16px;
  font-weight: 500;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background: rgba(153, 153, 153, 0.4);
  }
  &:disabled {
    cursor: default;
    color: rgba(0, 0, 0, 0.26);
    background: none;
  }
`

export const FloatingButton = styled.button`
  width: 56px;
  height: 56px;
  border-radius: 28px;
  background: black;
  color: white;
  cursor: pointer;
`
