import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { Seasons } from './Seasons'
import { Events } from './Events'
import { hexToRgba, hexToHexWithAlpha } from '../../utils/colorConversion'

export function YearCategoryTable({ categories, eventsByYears, seasonsByYear }) {
  const years = Array.from(new Set(Object.keys(eventsByYears).concat(Object.keys(seasonsByYear)))).sort((a, b) => a - b)

  const backgroundColors = categories.map((category) => hexToHexWithAlpha(category.color, 0.1, '#fff'))
  const borderColors = categories.map((category) => hexToHexWithAlpha(category.color, 0.2, '#fff'))

  return (
    // position:stickyと合わせたときに、borderが消えるのを防ぐためのstyle
    <table style={{ borderCollapse: 'separate', borderSpacing: 0 }}>
      <thead>
        <tr>
          <th></th>
          {categories.map((category, i) => (
            <StyledTh
              key={i}
              style={{
                background: backgroundColors[i],
                color: category.color,
                borderBottom: `1px solid ${borderColors[i]}`
              }}
            >
              <CategoryTitle>{category.title}</CategoryTitle>
            </StyledTh>
          ))}
        </tr>
      </thead>
      <tbody>
        {years.map((year, i) => (
          <tr key={i}>
            <Year>{year}</Year>
            {categories.map((category, j) => (
              <StyledTd
                style={{
                  background: backgroundColors[j],
                  borderBottom: `1px solid ${borderColors[j]}`
                }}
                key={`${i}-${j}`}
              >
                <Seasons
                  key={`${i}-${j}-seasons-start`}
                  seasons={seasonsByYear[year]?.filter(
                    (season) => season.category.title === category.title && season.start_year === year
                  )}
                  year={year}
                />
                <Events
                  key={`${i}-${j}-events`}
                  events={eventsByYears[year]?.filter((event) => event.category.title === category.title)}
                />
                <Seasons
                  key={`${i}-${j}-seasons-end`}
                  seasons={seasonsByYear[year]?.filter(
                    (season) => season.category.title === category.title && season.end_year === year
                  )}
                  year={year}
                />
              </StyledTd>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const StyledTh = styled.th`
  position: sticky;
  top: var(--header-height);
  max-width: var(--tableview--column-width);
  vertical-align: top;
  text-align: left;
  box-sizing: border-box;
`

const CategoryTitle = styled.h2`
  margin: 16px 0;
  padding: var(--tableview--column-padding);
  font-size: var(--tableview--header-font-size);
  line-height: 1.25;
`

const Year = styled.td`
  position: sticky;
  left: 0;

  vertical-align: top;
  padding: 0;
  padding-left: 10px;
  padding-right: 1em;

  background-color: var(--tableview--background-color);
  z-index: 1;

  font-size: var(--tableview--column-font-size);
  line-height: 2em;
  font-weight: bold;
  color: var(--listview--year-color);

  border-bottom: 1px solid rgba(200, 200, 200, 0.4);
  box-sizing: border-box;
`

const StyledTd = styled.td`
  vertical-align: top;
  max-width: var(--tableview--column-width);
  padding: var(--tableview--column-padding);
  font-size: var(--tableview--column-font-size);
  line-height: 2em;
  font-weight: bold;
  box-model: border-box;
`
