// const baseFontSize = 12
const base_size = 12

const Size = {
  base: {
    font_size: base_size,
    line_height: base_size * 1.5
  },
  header_height: base_size * 3,
  season: {
    width: base_size * 11,
    border_left_width: 2,
    padding_left: 8,
    font_size: 14,
    line_height: base_size * 1.5,
    whole_width: 150 + base_size * 4
  },
  popup: {
    padding_left: base_size,
    header: {
      font_size: base_size * 2,
      line_height: base_size * 4
    },
    item: {
      font_size: 14,
      line_height: 24,
      margin_top: 7
    }
  }
}

export default Size