import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import Size from './constants/Size'

import { EventsByYear } from './ListView/EventsByYear'
import { EventDetail } from './ListView/EventDetail'

import { getEventsByYear, categoriesToEvents } from '../utils/getEventsWithCondition'

const GlobalStyle = createGlobalStyle`
  :root {
    --lattice-color: #ADD8E6;
    --listview--border-color: #CCCCCC;
    --listview--text-color: #333333;
    --listview--year-color: #C8C8C8;
    --listview--background-color: #FCFCF9;
    --listview--font-size: 20px;
    --listview--event-detail-width: 724px;
    --listview--event-detail-margin: 140px 32px auto;
    --listview--event-detail--content-width: 660px;
    --listview--event-detail--content-image-height: 660px;
    --listview--event-detail--title-font-size: ${Size.base.font_size * 2}px;
    --listview--event-detail--meta-font-size: ${(Size.base.font_size * 4) / 3}px;
    --listview--event-detail--description-font-size: ${Size.base.font_size * 2}px;
  }
`

export function ListView({ categories }) {
  const eventsByYears = getEventsByYear(categoriesToEvents(categories))

  const [selectedEvent, setSelectedEvent] = useState(null)

  function selectEvent(event) {
    setSelectedEvent(event)
  }

  function closeEventDetail() {
    setSelectedEvent(null)
  }

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <EventList>
          {Object.keys(eventsByYears)
            .sort((a, b) => a - b)
            .map((year) => (
              <EventsByYear
                year={year}
                events={eventsByYears[year]}
                key={year}
                selectEvent={selectEvent}
                selectedEvent={selectedEvent}
              />
            ))}
        </EventList>
        <EventDetail event={selectedEvent} close={closeEventDetail} />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  margin-top: var(--header-height);
  margin-left: 10px;
  background-color: var(--listview--background-color);
`

const EventList = styled.ul`
  padding: 20px 0 40px;
`
