import React from 'react'

import { FigureYoutube } from '../Figure/FigureYouTube'
import { FigureVimeo } from '../Figure/FigureVimeo'
import { FigureStill } from '../Figure/FigureStill'

import { isYoutubeUrl, isVimeoUrl, isImageUrl } from '../../utils/validations/validateUrl'

export function EventPopupFigure({ url, ...props }) {
  if (!url) return null

  const style = {
    width: 220,
    marginBottom: 10
  }

  if (isYoutubeUrl(url)) return <FigureYoutube url={url} width='220px' style={style} />
  if (isVimeoUrl(url)) return <FigureVimeo url={url} width='220px' style={style} />
  if (isImageUrl(url)) return <FigureStill url={url} width='220px' style={style} />

  return null
}
