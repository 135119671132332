import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import popupIcon from '../images/event/popup-icon.svg'
import linkIcon from '../images/event/link-icon.svg'

import { isValidURL } from '../../utils/validations/validateUrl'

export function Event({ event, selectEvent, isSelected, ...props }) {
  function onClick(e) {
    e.preventDefault()
    selectEvent(event)
  }

  return (
    <StyledLi isSelected={isSelected} isSelectable={hasPopup(event)}>
      <SeasonTitle style={{ color: event.category.color }}>
        {event.season?.title}
        <Circle style={{ backgroundColor: event.category.color }} isHead={!event.season} />
      </SeasonTitle>
      {hasPopup(event) ? (
        <>
          <TitleSelectable onClick={onClick} isSelected={isSelected}>
            {event.title}
          </TitleSelectable>
          <Icon src={popupIcon.src} alt='popup-icon' />
        </>
      ) : hasExternalLink(event) ? (
        <>
          <TitleWithLink href={event.url} target='_blank' rel='noopener noreferrer'>
            {event.title}
          </TitleWithLink>
          <Icon src={linkIcon.src} alt='link-icon' />
        </>
      ) : (
        <Title>{event.title}</Title>
      )}
    </StyledLi>
  )
}

function hasPopup(event) {
  return event.popup.description || event.popup.imageUrl
}

function hasExternalLink(event) {
  return isValidURL(event.url)
}

const FontStyle = css`
  /* borderを近くに寄せたいので、
     line-heightを小さくして、marginを足し、
     見かけのheightを2emにする。 */
  font-size: var(--listview--font-size);
  line-height: 1.6;
  height: 1.6em;
  box-sizing: border-box;
`

const StyledLi = styled.li`
  ${FontStyle};
  margin: 0.2em 0;
  white-space: nowrap;
  ${({ isSelectable }) =>
    isSelectable &&
    css`
      cursor: pointer;
    `}
  ${({ isSelected }) =>
    isSelected &&
    css`
      border-bottom: 2px solid var(--lattice-color);
    `}
`

const SeasonTitle = styled.span``

const TitleStyle = css`
  ${FontStyle};
  display: inline-block;
  color: var(--listview--text-color);
`

const Title = styled.span`
  ${TitleStyle};
`

const TitleSelectable = styled.span`
  ${TitleStyle};
  border-bottom: 2px solid var(--listview--border-color);
  &:hover {
    opacity: 0.6;
    border-color: var(--lattice-color);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: var(--lattice-color);
    `}
`

const TitleWithLink = styled.a`
  ${TitleStyle};
  text-decoration: none;
  border-bottom: 2px solid var(--lattice-color);
`

const Circle = styled.div`
  display: inline-block;
  width: 1em;
  height: 1em;
  margin: 0.32em 0.375em 0;
  border-radius: 48%;
  vertical-align: top;
  transform-origin: center;
  transform: scale(1, 0.9);
  ${({ isHead }) =>
    isHead &&
    css`
      margin-left: 0.175em;
    `}
`

const Icon = styled.img`
  margin-top: 6px;
  margin-left: 10px;
  width: var(--listview--font-size);
  vertical-align: top;
`
