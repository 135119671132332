export function getSeasonByYear(cateogries) {
  const seasons = cateogries.map((category) => category.seasons).flat()
  const seasonsByYear = {}
  seasons.forEach((season) => {
    const start_year = `${season.start_year}`
    if (!seasonsByYear[start_year]) {
      seasonsByYear[start_year] = []
    }
    seasonsByYear[start_year].push(season)

    if (!!season.end_year) {
      const end_year = `${season.end_year}`
      if (!seasonsByYear[end_year]) {
        seasonsByYear[end_year] = []
      }
      seasonsByYear[end_year].push(season)
    }
  })

  return seasonsByYear
}
