import React from 'react'

import { FigureYoutube } from '../Figure/FigureYouTube'
import { FigureVimeo } from '../Figure/FigureVimeo'
import { FigureStill } from '../Figure/FigureStill'

import { isImageUrl, isVimeoUrl, isYoutubeUrl } from '../../utils/validations/validateUrl'

export function TableFigure({ url, description }) {
  if (!url) return null
  // 両端の縦・横の線を表示するために、Containerより2px小さくしておく
  const stillStyle = { width: 249, height: 249, objectFit: 'contain', margin: 1 }
  const videoStyle = {
    // object-fit: contain; と同じ状態にする
    width: 249, // 両端の縦線を表示するために、Containerより2px小さくしておく
    height: 187.5, // 4:3
    margin: 'auto 1px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }

  if (isYoutubeUrl(url)) return <FigureYoutube url={url} style={videoStyle} />
  if (isVimeoUrl(url)) return <FigureVimeo url={url} style={videoStyle} />
  if (isImageUrl(url)) return <FigureStill url={url} alt={description} style={stillStyle} />

  return null
}
