import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import { ImageTile } from './ImageView/ImageTile'

import { categoriesToEvents, getEventsByYear } from '../utils/getEventsWithCondition'

const GlobalStyle = createGlobalStyle`
  :root {
    --image-tile-background-color: #333333;
    --image-tile-year-font-size: 32px;
    --image-tile-figure-background-color: #222222;
  }
`

export function ImageView({ categories }) {
  const eventsByYears = getEventsByYear(categoriesToEvents(categories).filter((event) => !!event.popup.imageUrl))

  return (
    <>
      <GlobalStyle />
      <StyledDiv>
        <ImageTileList>
          {Object.keys(eventsByYears).map((year) =>
            eventsByYears[year].map((event, index) => {
              return (
                <ImageTile
                  event={event}
                  isFirst={index === 0}
                  isLast={index === eventsByYears[year].length - 1}
                  key={`image-tile__${year}_${index}`}
                />
              )
            })
          )}
        </ImageTileList>
      </StyledDiv>
    </>
  )
}

const StyledDiv = styled.div`
  position: relative;
  margin-top: var(--header-height);
  padding: 0 10px;
  min-width: calc(100vw - 20px);
  width: 100%;
  min-height: calc(100vh - var(--header-height));
  background: var(--image-tile-background-color);
`

const ImageTileList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: calc(100vw - 20px);
`
