import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'

import COLOR from '../constants/Color'

import linkIcon from '../images/event/link-icon.svg'
import popupIcon from '../images/event/popup-icon.svg'
import { EventPopup } from './EventPopup'

import { ThemeContext } from '../../context/ThemeContext'

import { isValidURL } from '../../utils/validations/validateUrl'
import { createBudouxedHTML } from '../../utils/createBudouxedHTML'
import { hexToRgba } from '../../utils/colorConversion'

export function Event({ event, seasonTitle, seasonStartYear, index, seasonIndex, ...props }) {
  const theme = useContext(ThemeContext)
  const yearHeight = theme.yearHeight

  const [isPopupShown, setIsPopupShown] = useState(false)
  function handleIsPopupShown() {
    setIsPopupShown(!isPopupShown)
  }

  const hasPopup = event.popup.description || event.popup.imageUrl

  const borderColor = event.category.color
  const backgroundColor = hexToRgba(event.category.color, 0.3)

  return (
    <StyledLi
      data-year={event.year}
      className='Event'
      style={getEventStyle({ yearHeight, event, seasonStartYear, seasonIndex, index })}
    >
      <Wrapper>
        <Circle year={event.year} index={index} data-id={event.id} />
        {hasPopup ? (
          <TitleWithPopup onClick={handleIsPopupShown} title={event.year} style={{ background: backgroundColor }}>
            <StyledSpan borderColor={borderColor} dangerouslySetInnerHTML={createBudouxedHTML(event.title)} />
            <Icon src={popupIcon.src} alt='popup-icon' />
          </TitleWithPopup>
        ) : isValidURL(event.url) ? (
          <TitleWithLink
            href={event.url}
            target='_blank'
            rel='noopener noreferrer'
            style={{ background: backgroundColor }}
          >
            <StyledSpan borderColor={borderColor} dangerouslySetInnerHTML={createBudouxedHTML(event.title)} />
            <Icon src={linkIcon.src} alt='link-icon' />
          </TitleWithLink>
        ) : (
          <Title
            title={event.year}
            dangerouslySetInnerHTML={createBudouxedHTML(event.title)}
            style={{ background: backgroundColor }}
          ></Title>
        )}
      </Wrapper>
      {hasPopup && isPopupShown && <EventPopup event={event} onClick={handleIsPopupShown} />}
    </StyledLi>
  )
}

function getEventStyle({ yearHeight, event, seasonStartYear, seasonIndex, index }) {
  const top = (event.year - seasonStartYear) * yearHeight + 1
  return {
    top: `${top}px`,
    left: getEventLeft(seasonIndex, index)
  }
}

function getEventLeft(seasonIndex, index) {
  // CategoryのEventListに置かれる場合
  if (seasonIndex !== undefined) {
    if (index) {
      return `calc(${seasonIndex + index} * var(--timelineview--event-margin-left) + 0.5rem - 8px)`
    }
    return `calc(${seasonIndex} * var(--timelineview--event-margin-left) + 0.5rem - 8px)`
  }

  // SeasonのEventListに置かれる場合
  if (index !== undefined) {
    return `calc(${index} * var(--timelineview--event-margin-left) + 8px - 8px)`
  }
  return '0'
}

const StyledLi = styled.li`
  position: absolute;
  color: ${COLOR.base.text};
`

const Wrapper = styled.div`
  position: relative;
  z-index: 1; // Relationは、z-index: 0 */
`

const YearMixin = ({ year }) => css`
  &::before {
    content: '${({ year }) => `${year}`}';
    position: absolute;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    width: 2.75rem;
    left: calc(-3rem - 2px);
    top: -0.25rem;
    text-align: right;
  }
`

const Circle = styled.div`
  display: block;
  position: absolute;
  z-index: 0;
  width: var(--base-font-size);
  height: var(--base-font-size);
  border-radius: calc(var(--base-font-size) / 2);
  left: calc(
    -0.5 * var(--base-font-size) - var(--timelineview--season-padding-left) - var(
        --timelineview--season-border-left-width
      ) / 2 - 1px
  );
  top: calc((var(--timelineview--season-line-height) - var(--listview--season-font-size)) / 4 * 3);
  background-color: ${COLOR.base.border};
  /* Season内で、一番左=線の上にCircleが表示されるEventだけ、年数を表示 */
  ${({ index, year }) => index === 0 && YearMixin({ year })}
`

const LineClampMixin = css`
  display: -webkit-box;
  -webkit-line-clamp: var(--timelineview--event-title-lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const TitleMixin = css`
  display: block;
  position: relative;
  z-index: 0;
  margin-top: -1px;
  width: var(--timelineview--season-width);
  font-size: var(--base-font-size);
  font-weight: bold;
  line-height: var(--base-line-height);
  line-break: strict;
  /* text-align: justify; */
  /* text-justify: inter-ideograph; */
  /* word-break: break-all; */
  hyphens: auto;
  /* ${LineClampMixin} */
`

const TitleWithLink = styled.a`
  ${TitleMixin}
  text-decoration: none;
  &:hover {
    opacity: 0.6;
  }
`

const TitleWithPopup = styled.div`
  ${TitleMixin}
  color: ${COLOR.base.text};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`

const Title = styled.div`
  ${TitleMixin}
`

const StyledSpan = styled.span`
  border-bottom: 2px solid;
  border-color: ${({ borderColor }) => borderColor};
`

const Icon = styled.img`
  margin-top: 3px;
  margin-left: 3px;
  width: var(--base-font-size);
  vertical-align: top;
`
