import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import Size from '../constants/Size'
import COLOR from '../constants/Color'
import { SeasonLineLength } from '../constants/SeasonLineLength'

import { RadioGroup } from './RadioGroup'
import { SelectValue } from './SelectValue'
import { ToggleSwitch } from './ToggleSwitch'

export function SettingsPopup({
  hideModal,
  yearHeight,
  drawLineEvery,
  setTheme,
  setShowMinimap,
  showMinimap,
  seasonLineLength,
  setSeasonLineLength,
  ...props
}) {
  const radios = {
    title: 'draw_line_every',
    items: [
      { value: 1, label: '1年ごと' },
      { value: 10, label: '10年ごと' }
    ]
  }

  const timeUnitHeightOptions = {
    type: 'number',
    items: [
      { value: 1 * Size.base.line_height, label: '1行' },
      { value: 2 * Size.base.line_height, label: '2行' },
      { value: 3 * Size.base.line_height, label: '3行' },
      { value: 4 * Size.base.line_height, label: '4行' },
      { value: 5 * Size.base.line_height, label: '5行' },
      { value: 6 * Size.base.line_height, label: '6行' },
      { value: 7 * Size.base.line_height, label: '7行' }
    ]
  }

  const SeasonLineLengthOptions = {
    type: 'number',
    items: SeasonLineLength.map((option) => ({ value: option, label: `${option}文字` }))
  }

  return (
    <>
      <ContentWrapper>
        <div>
          <StyledHeader>設定</StyledHeader>
        </div>
        <ul>
          <StyledLi>
            <InputTitle>年表の水平線は</InputTitle>
            <RadioGroup
              radios={radios}
              value={drawLineEvery}
              setValue={(value) => setTheme({ newDrawLineEvery: value })}
            />
          </StyledLi>
          <StyledLi>
            <InputTitle>年表の1年の高さは</InputTitle>
            <SelectValue
              name='Select-TimeUnitHeight'
              options={timeUnitHeightOptions}
              value={yearHeight}
              setValue={(value) => setTheme({ newYearHeight: value })}
            />
          </StyledLi>
          <StyledLi>
            <InputTitle>年表のイベントの横幅は</InputTitle>
            <SelectValue
              name='Select-SeasonLineLength'
              options={SeasonLineLengthOptions}
              value={seasonLineLength}
              setValue={(value) => setSeasonLineLength({ newSeasonLineLength: value })}
            />
          </StyledLi>
          <StyledLi>
            <InputTitle>Minimapの表示</InputTitle>
            <ToggleSwitch value={showMinimap} setValue={setShowMinimap} style={{ marginTop: 1 }} />
          </StyledLi>
        </ul>
      </ContentWrapper>
    </>
  )
}

const ContentWrapper = styled.div`
  width: 202px;
`

const StyledHeader = styled.h1`
  font-size: ${Size.popup.header.font_size}px;
  line-height: ${Size.popup.header.line_height}px;
  /* 英語の場合は、500の方がよいが、 日本語だと700の方が適切*/
  /* font-weight: 500; */
  margin: 0;
  padding-left: ${Size.popup.padding_left}px;
  color: ${COLOR.popup.header.text};
  background-color: ${COLOR.popup.header.background};
`

const InputTitle = styled.div`
  font-weight: bold;
  font-size: ${Size.popup.item.font_size}px;
  line-height: ${Size.popup.item.line_height}px;
`

const StyledLi = styled.li`
  height: 60px;
  padding-top: ${Size.popup.item.margin_top}px;
  padding-left: ${Size.popup.padding_left}px;
  border-bottom: 1px solid ${COLOR.popup.content.border};
  color: ${COLOR.popup.content.text};
  background-color: ${COLOR.popup.content.background};
`
