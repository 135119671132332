import styled from 'styled-components'
import ReactModal from 'react-modal'
import { ModalProvider } from 'react-modal-hook'

import { ChangeSheetButton } from './Header/ChangeSheetButton'
import { SettingButton } from './Header/SettingsButton'
// import EditButton from './Header/Edit.svg'
import { DownloadButton } from './Header/DownloadButton'

// アプリのルートを識別するクエリセレクタを指定する。
ReactModal.setAppElement('#__next')

export function Header({
  title,
  mode,
  setMode,
  setTheme,
  setSheetId,
  setSeasonLineLength,
  showMinimap,
  setShowMinimap,
  ...props
}) {
  return (
    <Wrapper>
      <Title>
        PRIESTLEY<Span>｜</Span>
        {title}
        <Span>｜</Span>
        <ModeSelector mode={mode} setMode={setMode} />
      </Title>

      <Buttons>
        {mode === 'timeline' && <DownloadButton title={title} />}
        {/* <Icon>
            <img src={EditButton} alt='Edit Timeline' title='Edit Timeline' width={24} height={24} />
          </Icon> */}
        <ModalProvider>
          <ChangeSheetButton setSheetId={setSheetId} />
          <SettingButton
            setTheme={setTheme}
            showMinimap={showMinimap}
            setShowMinimap={setShowMinimap}
            setSeasonLineLength={setSeasonLineLength}
          />
        </ModalProvider>
      </Buttons>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;

  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-height);
  padding: 6px 10px 9px;
  background-color: #222;
  text-align: left;
  z-index: 100;
`

const Title = styled.div`
  /* display: inline-block; */
  color: white;
  font-size: var(--header-font-size);
  font-weight: 700;
  line-height: 1;
  font-feature-settings: 'palt';
  font-kerning: auto;
`

const Span = styled.span`
  font-feature-settings: normal;
`

// TODO: #229 ModeSelectorのスタイルを書き直す
function ModeSelector({ mode, setMode, ...props }) {
  return (
    <StyledSelect value={mode} onChange={(e) => setMode({ newMode: e.target.value })}>
      <option value='timeline'>タイムライン</option>
      <option value='table'>年表</option>
      <option value='list'>年譜</option>
      <option value='image'>イメージ</option>
    </StyledSelect>
  )
}

const StyledSelect = styled.select`
  vertical-align: top;
  cursor: pointer;
`

const Buttons = styled.div`
  display: flex;
  gap: 12px;
`