import React from 'react'

import { FigureYoutube } from '../Figure/FigureYouTube'
import { FigureVimeo } from '../Figure/FigureVimeo'
import { FigureStill } from '../Figure/FigureStill'

import { isImageUrl, isVimeoUrl, isYoutubeUrl } from '../../utils/validations/validateUrl'

export function ImageTileFigure({ url, description }) {
  if (!url) return null
  const stillStyle = { width: 250, height: 250, objectFit: 'contain' }

  const videoStyle = {
    // object-fit: contain; と同じ状態にする
    width: 250,
    height: 187.5, // 4:3
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }

  if (isYoutubeUrl(url)) return <FigureYoutube url={url} style={videoStyle} />
  if (isVimeoUrl(url)) return <FigureVimeo url={url} style={videoStyle} />
  if (isImageUrl(url)) return <FigureStill url={url} alt={description} style={stillStyle} />

  return null
}
