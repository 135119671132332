import React from 'react'
import styled from 'styled-components'

import { Category } from './Category'

export function CategoryTable({ categories, resizeListener, ...props }) {
  return (
    <StyledUl>
      {resizeListener}
      {categories.map((category, index) => (
        <Category
          category={category}
          isFirst={index === 0}
          isLast={index === categories.length - 1}
          key={'cateogory-' + category.title}
        />
      ))}
    </StyledUl>
  )
}

const StyledUl = styled.ul`
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  top: 0;
  left: 0;
  min-height: 100%;
`
