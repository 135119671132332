import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Event } from './Event'

export function EventsByYear({ year, events, selectEvent, selectedEvent }) {
  return (
    <StyledLi>
      <Year>{year}</Year>
      <Events>
        {events.map((event) => (
          <Event key={event.id} event={event} selectEvent={selectEvent} isSelected={event.id === selectedEvent?.id} />
        ))}
      </Events>
    </StyledLi>
  )
}

const StyledLi = styled.li`
  display: flex;
  font-size: var(--listview--font-size);
  line-height: 2em;
  font-weight: bold;
`
const Year = styled.div`
  align-self: flex-start;
  color: var(--listview--year-color);
  padding-right: 1em;
`

const Events = styled.ul`
  list-style: none;
  width: 100%;
`
