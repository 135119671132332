const ranges = {
  category: 'Category!A2:D300',
  season: 'Season!A2:D300',
  event: 'Event!A2:H1000',
  relation: 'Relation!A2:F1000'
}

export const API_KEY = 'AIzaSyAchpfVXj1NZe11eo2IQKx2KUUusPttDI0'
export const API_BASE_URL = 'https://sheets.googleapis.com/v4/spreadsheets/'
export const API_RANGE_PARAMETER = `
ranges=${ranges.category}
&ranges=${ranges.season}
&ranges=${ranges.event}
&ranges=${ranges.relation}`

export const DEFAULT_SHEET_ID = '175FTHoWrUszBEc_U8Ren1NNkaIuLmwrfcoI1tkKGT90'
