import { largeElementToImageBlob } from '@oshimaryo/large-element-to-image-blob'

import { Icon } from '../shared/components/Icon'
import DownloadIcon from './Download.svg'

export function DownloadButton({ title }) {
  return (
    <Icon onClick={() => saveImage(document.querySelector('.RenderTarget'), `${title}｜PRIESTLEY.jpg`)}>
      <img
        src={DownloadIcon.src}
        alt='タイムラインを画像として保存'
        title='タイムラインを画像として保存'
        width={24}
        height={24}
      />
    </Icon>
  )
}

async function saveImage(element, filename = 'timeline.jpg') {
  if (!element) {
    alert('画像が生成できません')
    return
  }

  // TODO: #295 画像生成に伴うインタラクションの追加
  // 1. overlayで画面をさわれなくする
  // 2. 画面の一番上までscrollする
  // 3. もとの位置に戻る

  // html2canvasで font-feautures-settings: 'halt' が未対応のため、一時的にnormalにする
  document.querySelector('html').classList.add('html2canvas')

  const a = document.createElement('a')
  const blob = await largeElementToImageBlob(element, { type: 'image/jpeg', quality: 1.0 })
  a.href = URL.createObjectURL(blob)
  a.download = filename
  a.click()

  // font-feautures-settings: 'halt'に戻す
  document.querySelector('html').classList.remove('html2canvas')
}
