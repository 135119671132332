import { useState } from 'react'
import styled from 'styled-components'

import COLOR from '../constants/Color'
import { Button } from '../shared/components/Button'

export function ChangeSheetPopup({ hideModal, changeSheet, ...props }) {
  const [input, setInput] = useState({ hasValue: false, onFocused: false })
  const [sheetId, setSheetId] = useState({ isCorrect: false, value: undefined })

  function handleInput(e) {
    // check google sheets URL & get id
    const url = e.target.value.trim()
    const matches = /\/([\w-_]{15,})\/(.*?gid=(\d+))?/.exec(url)
    if (matches) {
      setSheetId({ isCorrect: true, value: matches[1] })
      setInput({ onFocused: input.onFocused, hasValue: true })
    } else {
      setSheetId({ isCorrect: false, value: undefined })
      setInput({ onFocused: input.onFocused, hasValue: e.target.value.length !== 0 })
    }
  }

  function handleInputOnFocus(e) {
    setInput({ onFocused: true, hasValue: e.target.value.length !== 0 })
  }

  function handleInputOnBlur(e) {
    setInput({ onFocused: false, hasValue: e.target.value.length !== 0 })
  }

  return (
    <>
      <ContentWrapper>
        <div>
          <StyledHeader>年表を変更する</StyledHeader>
        </div>
        <InputTitle isVisible={input.hasValue && input.onFocused}>年表用のGoogle スプレッドシートのURL</InputTitle>
        <StyledInput
          type='url'
          placeholder='Google スプレッドシートのURL'
          onChange={(e) => handleInput(e)}
          onFocus={(e) => handleInputOnFocus(e)}
          onBlur={(e) => handleInputOnBlur(e)}
        />
        <Result isCorrect={sheetId.isCorrect} isEmpty={!input.hasValue} />
        <ButtonContainer>
          <CancelButton onClick={hideModal}>キャンセル</CancelButton>
          <OkButton onClick={() => changeSheet({ newSheetId: sheetId.value })} disabled={!sheetId.isCorrect}>
            OK
          </OkButton>
        </ButtonContainer>
      </ContentWrapper>
    </>
  )
}

const ContentWrapper = styled.div`
  margin: 24px;
  width: 384px;
`

const StyledHeader = styled.h1`
  font-size: 2rem;
  line-height: 1;
  color: black;
  margin: 0;
  padding-bottom: 2rem;
`

const InputTitle = styled.div`
  margin-left: 1px;
  font-size: 1rem;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`

const StyledInput = styled.input`
  margin-top: 3px;
  margin-bottom: 3px;
  padding-bottom: 3px;
  width: 100%;
  font-size: 1.25rem;
  background: white;
  border: none;
  border-bottom: 1px solid black;
  &:focus {
    caret-color: black;
    @include placeholder {
      color: transparent;
    }
  }
`

function Result({ isCorrect, isEmpty, ...props }) {
  if (isEmpty) return <div style={{ display: 'block', height: '1.5em', width: '100%' }} />
  if (isCorrect) return <div style={{ color: 'green' }}>OK</div>
  return <div style={{ color: 'red' }}>URLが適切ではありません。</div>
}

const ButtonContainer = styled.div`
  width: 100%;
  text-align: right;
`

const CancelButton = styled(Button)`
  font-size: 1.25rem;
`

const OkButton = styled(Button)`
  margin-right: 6px;
  font-size: 1.25rem;
  &:not(:disabled) {
    background-color: ${COLOR.button.submit.background};
    color: ${COLOR.button.submit.text};
  }
`
