import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import urlParser from 'js-video-url-parser/lib/base'
import 'js-video-url-parser/lib/provider/vimeo'

import Vimeo from '@u-wave/react-vimeo'

export function FigureVimeo({ url, style }) {
  if (!url) return null

  const videoInfo = urlParser.parse(url)

  return (
    <VideoWrapper style={style}>
      <Vimeo video={videoInfo.id} responsive={true} />
    </VideoWrapper>
  )
}

const VideoWrapper = styled.div`
  position: relative;
  aspect-ratio: ${({ showVideo }) => (showVideo ? 16 / 9 : 4 / 3)};
  height: 100%;
  overflow: hidden;
`
