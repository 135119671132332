import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import Head from 'next/head'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import smoothscroll from 'smoothscroll-polyfill'
import useResizeAware from 'react-resize-aware'
import throttle from 'lodash.throttle'

import Size from '../components/constants/Size'
import { DEFAULT_SHEET_ID } from '../utils/constants'

import { Header } from '../components/Header'
import { TimelineView } from '../components/TimelineView'
import { TableView } from '../components/TableView'
import { ImageView } from '../components/ImageView'
import { ListView } from '../components/ListView'

import { DurationContext } from '../context/DurationContext'
import { ThemeContext } from '../context/ThemeContext'
import { SeasonLineLengthContext } from '../context/SeasonLineLengthContext'

import { getCategories } from '../utils/googleSheets/getCategories'
import { getSheetTitle } from '../utils/googleSheets/getSheetTitle'
import { getSheetId } from '../utils/googleSheets/getSheetId'
import { getIntParam, getParam } from '../utils/getParam'

const CreateOutline = dynamic(() => import('../utils/createOutline'), { ssr: false })

const GlobalStyle = createGlobalStyle`
  :root {
    --base-font-size: ${Size.base.font_size}px;
    --base-line-height: ${Size.base.line_height}px;
    --header-height: ${Size.base.font_size * 3}px;
    --header-font-size: ${Size.base.font_size * 2}px;
  }
  html {
    scroll-margin-top: var(--header-height);
  }
`

export default function Index() {
  const router = useRouter()

  const [duration, setDuration] = useState({
    startYear: 10,
    endYear: 155,
    startDecade: 10,
    endDecade: 160
  })

  const [categories, setCategories] = useState([])
  useEffect(() => {
    if (categories.length === 0) return
    const startYear = Math.min(...categories.map((cat) => cat.start_year))
    const endYear = Math.max(...categories.map((cat) => cat.end_year))
    const startDecade = (Math.floor(startYear / 10) - 1) * 10
    const endDecade = (Math.ceil(endYear / 10) + 1) * 10

    setDuration({
      startYear,
      endYear,
      startDecade,
      endDecade
    })
  }, [categories])

  const [relations, setRelations] = useState([])

  const [theme, setTheme] = useState({
    yearHeight: Size.base.line_height * 1,
    drawLineEvery: 1
  })

  useEffect(() => {
    // about router.iReady:　https://gyazo.com/14a43b6f94e94f66ccfb811fb43c0058
    if (router.isReady) {
      setTheme({
        yearHeight: parseInt(router.query.yearHeight, 10) || theme.yearHeight,
        drawLineEvery: parseInt(router.query.drawLineEvery, 10) || theme.drawLineEvery
      })
      setSeasonLineLength(parseInt(router.query.seasonLineLength, 10) || seasonLineLength)
      setMode(router.query.mode || mode)
    }
  }, [router.isReady])

  const [seasonLineLength, setSeasonLineLength] = useState(11)

  const [resizeListener, sizes] = useResizeAware()
  // console.log(sizes)

  const [showMinimap, setShowMinimap] = useState(true)

  const [title, setTitle] = useState('')
  const [sheetId, setSheetId] = useState(getSheetId(router.asPath))

  async function changeSheet(newSheetId) {
    const { categories, relations } = await getCategories(newSheetId)
    setCategories(categories)
    setRelations(relations)

    // scroll To 1st Season
    document.querySelector('.Season--First')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })

    const sheetTitle = await getSheetTitle(newSheetId)
    setTitle(sheetTitle)
  }

  const throttledChangeSheet = useRef(
    throttle((newSheetId) => {
      changeSheet(newSheetId)
    }, 1000)
  )

  useEffect(() => throttledChangeSheet.current(sheetId), [sheetId])

  // mode: 'timeline', 'table', 'image', 'list'
  const [mode, setMode] = useState('timeline')

  useEffect(function init() {
    smoothscroll.polyfill()

    router.events.on('routeChangeStart', (url, { shallow }) => {
      if (url === '/' || url === '/priestley/') return
      const newSheetId = getSheetId(url)
      if (!!newSheetId && sheetId !== newSheetId) {
        setSheetId(newSheetId)
      }

      const newYearHeight = getIntParam(url, /yearHeight=[0-9]{1,}/)
      const newDrawLineEvery = getIntParam(url, /drawLineEvery=[0-9]{1,}/)

      if (!!newYearHeight && !!newDrawLineEvery) {
        setTheme({
          yearHeight: newYearHeight,
          drawLineEvery: newDrawLineEvery
        })
      }

      const newSeasonLineLength = getIntParam(url, /seasonLineLength=[0-9]{1,}/)
      if (!!newSeasonLineLength) {
        setSeasonLineLength(newSeasonLineLength)
      }

      const newMode = getParam(url, 'mode')
      if (!!newMode && isModeCorrct(newMode)) {
        setMode(newMode)
      }
    })
    return () => {}
  }, [])

  function changeUrl({
    newSheetId = sheetId,
    newYearHeight = theme.yearHeight,
    newDrawLineEvery = theme.drawLineEvery,
    newSeasonLineLength = seasonLineLength,
    newMode = mode
  }) {
    // console.log(`${process.env.NEXT_PUBLIC_URL}/`)
    const path = {
      pathname: `${process.env.NEXT_PUBLIC_URL}/`,
      query: {
        sheet_id: newSheetId,
        yearHeight: newYearHeight,
        drawLineEvery: newDrawLineEvery,
        seasonLineLength: newSeasonLineLength,
        mode: newMode
      }
    }

    if (sheetId !== newSheetId) {
      router.push(path, undefined, { shallow: true })
    } else {
      router.replace(path, undefined, { shallow: true })
    }
  }

  return (
    <StyledDiv width={mode === 'timeline' ? `${sizes.width}px` : '100%'} className='App'>
      <GlobalStyle />
      <Head>
        <title>{title}｜PRIESTLEY</title>
        <meta
          name='viewport'
          content='initial-scale=1.0, width=device-width, minimum-scale=0.5, maximum-scale=2, user-scalable=yes'
        />
        <meta name='robots' content='noindex' />
      </Head>

      {mode === 'table' && <TableView categories={categories} />}
      {mode === 'list' && <ListView categories={categories} />}
      {mode === 'image' && <ImageView categories={categories} />}

      <ThemeContext.Provider value={theme}>
        {mode === 'timeline' && (
          <>
            <DurationContext.Provider value={duration}>
              <TimelineView
                categories={categories}
                relations={relations}
                seasonLineLength={seasonLineLength}
                resizeListener={resizeListener}
              />
            </DurationContext.Provider>
            <CreateOutline
              shouldRender={showMinimap}
              width={Size.base.font_size * 16 + Size.season.padding_left} // = --timelineview--season-whole-width
            />
          </>
        )}
        <SeasonLineLengthContext.Provider value={seasonLineLength}>
          <Header
            title={title}
            mode={mode}
            setMode={changeUrl}
            setTheme={changeUrl}
            setSheetId={changeUrl}
            setSeasonLineLength={changeUrl}
            showMinimap={showMinimap}
            setShowMinimap={setShowMinimap}
          />
        </SeasonLineLengthContext.Provider>
      </ThemeContext.Provider>
      {/* <UpdateButton /> */}
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  min-width: 100px;
  width: ${({ width }) => width};
`

function isModeCorrct(mode) {
  return ['timeline', 'table', 'image', 'list'].includes(mode)
}
