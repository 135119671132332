import React, { useState } from 'react'
import styled from 'styled-components'
import COLOR from '../constants/Color'
import { hexToRgba } from '../../utils/colorConversion'

export function Relation({ from, to, ...props }) {
  const fromElement = findEvent(from.id)
  const toElement = findEvent(to.id)

  if (!fromElement || !toElement) return null

  const { length, left, top, theta } = getParameters(fromElement, toElement)
  // console.log(getParameters(fromElement, toElement))

  const styles = {
    width: `${length}px`,
    height: '1px',
    top: `${top}px`,
    left: `${left}px`,
    transform: `rotate(${theta}deg)`
  }

  // title example
  // -
  // 1935｜『複製時代の芸術』
  // ↓
  // 1962｜The Gutenberg Galaxy: the Making of Typographic Man
  const title = `${from.year}｜${from.title}\n↓\n${to.year}｜${to.title}`

  return <StyledDiv title={title} style={styles} />
}

const StyledDiv = styled.div`
  display: block;
  position: absolute;
  border-top: 6px dotted ${hexToRgba(COLOR.base.border, 0.6)};
  box-sizing: border-box;
  transform-origin: center;
  z-index: 0; // Eventは、z-index: 1
  cursor: pointer;
  &:hover {
    border-top: 3px solid ${COLOR.base.border};
  }
`

function findEvent(dataId) {
  const element = document.querySelector(`[data-id="${dataId}"]`)
  return element
}

function getParameters(fromElement, toElement) {
  const fromPosition = getPosition(fromElement)
  const toPosition = getPosition(toElement)

  const width = Math.abs(fromPosition.x - toPosition.x)
  const height = Math.abs(fromPosition.y - toPosition.y)

  const length = Math.hypot(width, height)

  const eventCircleRadius = 6
  const headerHeight = 36

  const left = (fromPosition.x + toPosition.x - length) / 2 + eventCircleRadius
  const top = (fromPosition.y + toPosition.y) / 2 - headerHeight + eventCircleRadius - 2
  const theta = getAngle(fromPosition, toPosition)

  return {
    length,
    top,
    left,
    theta
  }
}

function getPosition(element) {
  const clientRect = element.getBoundingClientRect()
  const x = window.pageXOffset + clientRect.left
  const y = window.pageYOffset + clientRect.top
  return {
    x,
    y
  }
}

// https://stackoverflow.com/a/9614122
function getAngle(from, to) {
  const dy = to.y - from.y
  const dx = to.x - from.x
  let theta = Math.atan2(dy, dx) // range (-PI, PI]
  theta *= 180 / Math.PI // rads to degs, range (-180, 180]
  //if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta
}
