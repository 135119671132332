import { getSheetId } from './getSheetId'
import { API_BASE_URL, API_KEY, API_RANGE_PARAMETER } from '../constants'

export function buildUrlForGetSheetTitle(sheetId) {
  // TODO: #51 sheetId の error handlingする場所を決める
  // if (!sheetId) {
  //   console.error('no sheet ID')
  //   return
  // }
  const SHEET_ID = sheetId ? sheetId : getSheetId(window.location.href)
  return `${API_BASE_URL}${SHEET_ID}?key=${API_KEY}`
}

export function buildUrlForGetCategories(sheetId) {
  // TODO: #51 sheetId の error handlingする場所を決める
  // if (!sheetId) {
  //   console.error('no sheet ID')
  //   return
  // }
  const SHEET_ID = sheetId ? sheetId : getSheetId(window.location.href)
  return `${API_BASE_URL}${SHEET_ID}/values:batchGet?${API_RANGE_PARAMETER}&key=${API_KEY}`
}
