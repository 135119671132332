import { useContext } from 'react'
import ReactModal from 'react-modal'
import { useModal } from 'react-modal-hook'

import { Icon } from '../shared/components/Icon'
import SettingsIcon from './Settings.svg'
import { SettingsPopup } from './SettingsPopup'

import { ThemeContext } from '../../context/ThemeContext'
import { SeasonLineLengthContext } from '../../context/SeasonLineLengthContext'

export function SettingButton({ setTheme, showMinimap, setShowMinimap, setSeasonLineLength, ...props }) {
  const customStyles = {
    overlay: {
      zIndex: 100,
      background: 'rgba(0, 0, 0, 0.6)'
    },
    content: {
      zIndex: 101,
      top: '50px',
      left: 'auto',
      right: '14px',
      bottom: 'auto',
      padding: 0,
      border: 'none'
    }
  }

  const { yearHeight, drawLineEvery } = useContext(ThemeContext)
  const seasonLineLength = useContext(SeasonLineLengthContext)

  // useModalで表示されるModal内部で参照する外部変数は、第２引数で指定する
  // https://github.com/mpontus/react-modal-hook#updating-modals
  const [showModal, hideModal] = useModal(() => {
    return (
      <ReactModal isOpen onRequestClose={hideModal} style={customStyles}>
        <SettingsPopup
          hideModal={hideModal}
          yearHeight={yearHeight}
          drawLineEvery={drawLineEvery}
          setTheme={setTheme}
          setShowMinimap={setShowMinimap}
          showMinimap={showMinimap}
          seasonLineLength={seasonLineLength}
          setSeasonLineLength={setSeasonLineLength}
        />
      </ReactModal>
    )
  }, [yearHeight, drawLineEvery, showMinimap, seasonLineLength])

  return (
    <Icon onClick={showModal}>
      <img src={SettingsIcon.src} alt='設定' title='設定' width={24} height={24} decoding='async' />
    </Icon>
  )
}
