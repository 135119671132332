const COLOR = {
  base: {
    border: '#666',
    text: '#555'
  },
  season: {
    after_stem_border: '#aaa'
  },
  year: {
    text: '#CCC'
  },
  button: {
    submit: {
      background: 'green',
      text: 'white'
    }
  },
  header: {
    background: '#222',
    title: '#fff'
  },
  popup: {
    header: {
      text: '#fff',
      background: '#222'
    },
    content: {
      text: '#555',
      background: '#fff',
      border: '#c8c8c8'
    }
  }
}

export default COLOR
