import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

import COLOR from '../constants/Color'

import { EventPopupFigure } from './EventPopupFigure'
import linkIcon from '../images/event/link-icon.svg'

import { isValidURL } from '../../utils/validations/validateUrl'
import { createBudouxedHTML } from '../../utils/createBudouxedHTML'
import { hexToRgba } from '../../utils/colorConversion'

export function EventPopup({ event, onClick, ...props }) {
  const categoryColor = event.category.color

  return (
    <StyledDiv style={{ borderColor: hexToRgba(categoryColor, 0.3) }}>
      <Container>
        <SeasonTitle color={categoryColor}>{event.season?.title}</SeasonTitle>
        {isValidURL(event.url) ? (
          <Title>
            <StyledLink href={event.url} borderColor={categoryColor} target='_blank' rel='noopener noreferrer'>
              <span dangerouslySetInnerHTML={createBudouxedHTML(event.title)}></span>
            </StyledLink>
            <Icon src={linkIcon.src} alt='link-icon' />
          </Title>
        ) : (
          <Title dangerouslySetInnerHTML={createBudouxedHTML(event.title)} />
        )}
        <Border borderColor={categoryColor} />
        <EventPopupFigure url={event.popup.imageUrl} />
        <Description>{event.popup.description}</Description>
        <CloseButton onClick={onClick} />
      </Container>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  position: absolute;
  z-index: 2;
  top: -1px;
  left: var(--timelineview--season-width);
  width: 250px;
  color: ${COLOR.base.text};
  background: transparent;
  overflow: hidden;
  box-sizing: content-box;
  border-width: 18px 20px;
  border-style: solid;
`

const Container = styled.div`
  position: relative;
  z-index: 2;
  padding: 20px 15px;
  background: white;
`

const SeasonTitle = styled.h2`
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: ${({ color }) => color};
`

const TitleMixin = css`
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`

const Title = styled.h1`
  ${TitleMixin};
`

const StyledLink = styled.a`
  ${TitleMixin}
  /* text-decoration: none; */
  /* border-bottom: 2px solid;
  border-color: ${({ borderColor }) => borderColor}; */
  &:hover {
    opacity: 0.6;
  }
`

const Icon = styled.img`
  margin-top: 3px;
  margin-left: 3px;
  width: var(--base-font-size);
  vertical-align: baseline;
`

const Border = styled.div`
  margin-bottom: 15px;
  display: block;
  width: 68px;
  height: 0;
  border-bottom: 2px solid;
  border-color: ${({ borderColor }) => borderColor};
`

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  font-feature-settings: normal;
  hyphens: auto;
  white-space: pre-wrap;
  /*
    改行文字があった場合は、改行する。空白文字はそのまま。
    https://developer.mozilla.org/ja/docs/Web/CSS/white-space
  */
`

const CloseButton = styled.div`
  position: absolute;
  right: 15px;
  top: 12px;
  width: 20px;
  height: 20px;
  opacity: 1;
  background-color: #c8c8c8;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &:before,
  &:after {
    position: absolute;
    left: 10px;
    top: 4px;
    content: ' ';
    height: 12px;
    width: 1px;
    background-color: white;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`
