import React from 'react'
import styled from 'styled-components'

import { ImageTileFigure } from './ImageTileFigure'

export function ImageTile({ event, isFirst, isLast }) {
  return (
    <StyledLi>
      <Year isFirst={isFirst}>{event.year}</Year>
      <Bar isLast={isLast} />
      <ImageContainer>
        <ImageTileFigure url={event.popup.imageUrl} description={event.popup.description} />
      </ImageContainer>
      <EventTitle>{event.title}</EventTitle>
      <MetaWrapper>
        <SeasonTitle>{event.season?.title}</SeasonTitle>
        {event.season?.title && ' / '}
        <CategoryTitle style={{ color: event.category.color }}>{event.category.title}</CategoryTitle>
      </MetaWrapper>
    </StyledLi>
  )
}

const StyledLi = styled.li`
  width: 256px;
  height: 360px;
`

const Year = styled.div`
  visibility: ${({ isFirst }) => (isFirst ? 'visible' : 'hidden')};
  display: block;
  margin: 0;
  margin-top: 12px;
  padding: 0;
  width: 100%;
  height: 44px;
  line-height: 44px;
  font-size: 32px;
  font-weight: bold;
`

// その年の最後のFigureのBarの右端は、少し短い
const Bar = styled.div`
  display: block;
  margin-bottom: 8px;
  width: ${({ isLast }) => (isLast ? 'calc(100% - 6px)' : '100%')};
  height: 8px;
  background-color: var(--image-tile-figure-background-color);
`

const ImageContainer = styled.div`
  display: block;
  position: relative;
  margin-bottom: 6px;
  width: 250px;
  height: 250px;
  background-color: var(--image-tile-figure-background-color);
`

const EventTitle = styled.p`
  display: block;
  margin: 0;
  padding: 0;
  height: 18px;
  max-width: 250px;
  font-size: 12px;
  line-height: 1.5;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const MetaWrapper = styled.p`
  display: block;
  margin: 0;
  padding: 0;

  height: 16px;
  max-width: 250px;
  font-size: 10px;
  line-height: 1.6;

  color: white;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SeasonTitle = styled.span``

const CategoryTitle = styled.span``
